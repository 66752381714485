window.initializeComponents = function() {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()
  $('[data-toggle="selectize"]').selectize()
  $('[data-toggle="datepicker"]').datepicker({language: 'pt-BR', autoclose: true})
}

window.initializeMasks = function() {
  $('[data-masker="date"]').mask('00/00/0000', {placeholder: "__/__/____", selectOnFocus: true})
  $('[data-masker="number"]').mask('0#', {selectOnFocus: true})
  $('[data-masker="decimal"]').mask('#.##0,00', {reverse: true, placeholder: "0,00", selectOnFocus: true})
  $('[data-masker="percent"]').mask('000,00 %', {reverse: true, placeholder: "0,00 %", selectOnFocus: true})
  
  $('[data-masker="precision"]').on('keyup', function() {
    $(this).val($(this).val().replace(/[^\d,]|\,(?=.*\,)/g, ''));
  })
}