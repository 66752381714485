window.renderTimeSeriesChart = function(context, data) {
  return new Chart(context, {
    data: {
      datasets: [
        {
          data: data,
          borderColor: 'rgba(81, 207, 110, 1)',
          lineTension: 0,
          pointRadius: 0,
          type: 'line',
          borderWidth: 2,
          backgroundColor: 'rgba(81, 207, 110, .05)'
        },
      ]
    },
    options: {
      maintainAspectRatio: false,
      legend: { display: false },
      tooltips: {
        intersect: false,
        mode: 'index',
        callbacks: {
          title: function(tooltipItems, data) {
            return moment(tooltipItems[0].xLabel, "YYYY-MM-DD").format('DD/MM/YYYY');
          },
          label: function(tooltipItem, data) {
            var zValue = data['datasets'][0]['data'][tooltipItem['index']]['z'];
            var yFormatted = data['datasets'][0]['data'][tooltipItem['index']]['y_formatted'];
            if (zValue) {
              return ' ' + yFormatted + ' (' + zValue + ')';
            } else {
              return ' ' + yFormatted;
            }
          }
        }
      },
      scales: {
        xAxes: [{
          type: 'time',
          distribution: 'series',
          offset: true,
          ticks: {
            major: {
              enabled: true,
              fontStyle: 'bold'
            },
            source: 'data',
            autoSkip: true,
            autoSkipPadding: 75,
            maxRotation: 0,
            sampleSize: 100
          },
          gridLines: {
            color: 'rgba(0, 0, 0, .0)',
          }
        }],
        yAxes: [{
          ticks: {
            callback: function(value, index, values) {
              return decimalFormatter.format(parseFloat(value).toFixed(2));
            }
          },
          gridLines: {
           color: 'rgba(0, 0, 0, .0)',
          }
        }],
      }
    }
  });
}