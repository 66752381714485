// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("selectize")
require("bootstrap-datepicker")
require("bootstrap-datepicker/dist/locales/bootstrap-datepicker.pt-BR.min")
require("jquery-mask-plugin")
require("moment")
require("moment/dist/locale/pt-br")
require("chart.js")

require("packs/initializer")
require("packs/custom_chart")

import "bootstrap"
import "../stylesheets/application"
import "@fortawesome/fontawesome-free/js/all";
import "controllers"

import moment from 'moment'

window.jQuery = $;
window.$ = $;

window.moment = moment;
moment.locale('pt-BR');

Chart.defaults.global.defaultFontFamily = 'Open Sans';
Chart.defaults.global.defaultFontSize = 12;
Chart.defaults.global.defaultFontStyle = 'bold';
Chart.defaults.global.defaultFontColor = '#666';

document.addEventListener("turbolinks:load", () => {
  // Components
  initializeComponents();

  // Masks
  initializeMasks();

  // Drawer toggle
  $('[data-toggle="drawer"]').on("click", function() {
    $('#sidenav, main').toggleClass('close-drawer')
  })
})

window.currencyFormatter = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
})

window.decimalFormatter = new Intl.NumberFormat('pt-BR', {
  maximumFractionDigits: 2,
})

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)


